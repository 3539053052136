import React, { useState } from 'react';
import { Liste } from '@jvs-group/jvs-mairistem-liste';
import {
  Form, Header, Icon, Popup,
} from '@jvs-group/jvs-mairistem-composants';
import { useHistory } from 'react-router';
import { NavLink } from 'react-router-dom';
import SubventionListeTable from '@/src/components/SubventionListe/SubventionListeTable';
import { Subvention } from '@/src/interfaces/subvention';
import EtiquettesInput from '@/src/components/Common/EtiquettesInput';

interface SubventionListeProps {
  subventions?: Array<Subvention>,
  count?: number,
  perPage?: number,
  loading?: boolean,
  onLoad?: any,
  onSearch?: any,
  onSort?: any,
  onSelected?: any,
  onExplored?: any,
  onExpanded?: any,
  state?: any,
  mode?: 'expander' | 'explorer',
  query? : any
}

const SubventionListe = (props : SubventionListeProps) => {
  const {
    subventions,
    count,
    perPage,
    loading,
    onLoad,
    onSort,
    onSelected,
    mode,
    query,
    ...handlers
  } = props;

  const [searchClosed, setSearchClosed] = useState(false);
  const [selectedEtiquettes, setSelectedEtiquettes] = useState([]);

  const handleChangeSearchClosed = () => {
    setSearchClosed(!searchClosed);
  };

  const handleEtiquetteChange = ({ etiquettes }) => {
    setSelectedEtiquettes(etiquettes);
  };

  React.useEffect(() => {
    query?.filter(() => ({
      ...!searchClosed && { cloture: searchClosed },
      ...(selectedEtiquettes.length > 0)
          && { 'etiquettesId[]': selectedEtiquettes.map((etiquette) => etiquette.identifiant) },
    }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchClosed, selectedEtiquettes]);

  const history = useHistory();
  const handleClick = () => {
    history.push('/subventionsPercues');
  };

  return (
    <>
      <Liste
        title="Subventions"
        mode={mode}
        searchable
        onExpand={handleClick}
        {...handlers}
      >
        <Liste.Header>
          {
              mode !== 'explorer'
                ? (
                  <Header size="small">
                    {count}
                    {' '}
                    {(count > 1) ? 'subventions' : 'subvention' }
                  </Header>
                ) : (
                  <Form>
                    <Form.Group widths="equal">
                      <Form.Field
                        className="etiquettes"
                        // @ts-expect-error allowAdditions
                        allowAdditions={false}
                        control={EtiquettesInput}
                        fluid
                        identifiantEntite={sessionStorage.getItem('identifiantEntite')}
                        onChange={handleEtiquetteChange}
                        selectedEtiquettes={selectedEtiquettes}
                      />
                      <Form.Checkbox
                        className="clotureCheckbox"
                        label="Inclure les aides des projets soldés"
                        onChange={handleChangeSearchClosed}
                        checked={searchClosed}
                      />
                    </Form.Group>
                  </Form>
                )
          }
        </Liste.Header>
        <Liste.Content>
          <SubventionListeTable
            items={subventions}
            loading={loading}
            count={count}
            perPage={perPage}
            onLoad={onLoad}
            onSort={onSort}
            onSelect={onSelected}
            isExplorer={mode === 'explorer'}
          />
        </Liste.Content>

      </Liste>

      <Popup
        content="Nouvelle fiche"
        trigger={(
          <NavLink to="/subventionsPercues/_" className="buttonAdd">
            <Icon
              name="plus"
              circular
              inverted
              link
              size="large"
              color="blue"
            />
          </NavLink>
      )}
      />

    </>
  );
};

export default SubventionListe;
