// @ts-nocheck

import React from 'react';
import { ListeTable } from '@jvs-group/jvs-mairistem-liste';
import { Subvention } from '@/src/interfaces/subvention';

interface SubventionListeTableProps {
  items: Array<Subvention>,
  count: number,
  perPage: number,
  loading: boolean,
  onLoad: any,
  onSelect: any,
  onSort: any,
  isExplorer: boolean
}

const SubventionListeTable = (props : SubventionListeTableProps) => {
  const {
    items,
    count,
    perPage,
    loading,
    onLoad,
    onSort,
    onSelect,
    isExplorer,
  } = props;

  return (
    <ListeTable
      items={items}
      loading={loading}
      count={count}
      perPage={perPage}
      onLoad={onLoad}
      onSort={onSort}
      onSelect={onSelect}
    >
      {
          isExplorer
            ? (
              <ListeTable.Header>
                <ListeTable.Column
                  sortable
                  name="libelle"
                  width={8}
                >
                  Libellé
                </ListeTable.Column>

                <ListeTable.Column
                  name="financeur"
                  width={6}
                >
                  Financeur
                </ListeTable.Column>
                <ListeTable.Column
                  name="projet"
                  width={6}
                >
                  Projet
                </ListeTable.Column>
                <ListeTable.Column
                  name="mntAttribue"
                  sortable
                  width={2}
                >
                  Montant attribué
                </ListeTable.Column>
              </ListeTable.Header>
            ) : (
              <ListeTable.Header>
                <ListeTable.Column
                  name="libelle"
                  width={8}
                >
                  Libellé
                </ListeTable.Column>
              </ListeTable.Header>
            )
      }
      {(subvention) => (
        <ListeTable.Row>
          <ListeTable.Cell name="libelle">{subvention?.libelle}</ListeTable.Cell>
          {
          isExplorer
          && (
            <>
              <ListeTable.Cell name="financeur">
                {subvention?.financeur?.nom}
                {' '}
                {subvention?.financeur?.prenom}
              </ListeTable.Cell>
              <ListeTable.Cell name="projet">{subvention?.projet?.libelle}</ListeTable.Cell>
              <ListeTable.Cell name="mntAttribue">{subvention?.mntAttribue.toFixed(2)}</ListeTable.Cell>
            </>
          )
      }
        </ListeTable.Row>
      )}
    </ListeTable>
  );
};

export default SubventionListeTable;
