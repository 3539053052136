import React from 'react';

import {
  useAutofocus,
} from '@jvs-group/jvs-mairistem-comptabilite';
import {
  Dropdown, Form, Tooltip, Grid, Input,
} from '@jvs-group/jvs-mairistem-composants';
import { ErrorField } from '@jvs-group/jvs-mairistem-fiche/lib/ts/types';
import { Date } from '@jvs-group/jvs-mairistem-finances-utils';
import { getTooltipError, isError } from '@/src/utils/tools';
import EtiquettesInput from '@/src/components/Common/EtiquettesInput';
import { Projet } from '@/src/interfaces/projet';
import Avatar from '@/src/components/Common/Avatar';
import UtilisateurProjetInput from '@/src/components/ProjetFichePanel/UtilisateurProjetInput/UtilisateurProjetInput';
import ProjetComposant from '@/src/components/ProjetFichePanel/ProjetComposant/ProjetComposant';
import { PROJET_STATUS_OPTIONS } from '@/src/constants/projetStatus';

type PickedProjetKeys = 'identifiant' |
'libelle' |
'montantHT' |
'montantTVA' |
'montantTTC' |
'dateFin' |
'identifiantCodeTVA' |
'etiquettes' |
'dateCreation' |
'dateExecution' |
'status' |
'identifiantDocInfo' |
'referenceInterne' |
'status' |
'identifiantEntite' |
'utilisateursProjet' |
'composants' |
'deletedComposants' |
'deletedUtilisateursProjet';

interface ProjetInformationProps {
  projet: Pick<Projet, PickedProjetKeys>,
  onChange: (any) => void,
  errors: Array<ErrorField>,
}

const ProjetInformation = ({
  projet = null,
  onChange = null,
  errors,
} : ProjetInformationProps) => {
  const mainInput = useAutofocus([projet?.identifiant]);

  const handleChange = (e, { name, value }) => {
    if (onChange) { onChange({ [name]: value }); }
  };

  const handleChangeDate = (value: string, name: string) => onChange?.({ [name]: value });

  return (
    <Form>
      <Grid stackable>
        <Grid.Row columns={2}>
          <Grid.Column width={12}>
            <Form.Group>

              <Tooltip
                {...getTooltipError(errors, 'subventionsPercues-referenceDossier')}
                trigger={(
                  <Form.Field
                    label="Intitulé"
                    type="text"
                    // @ts-expect-error
                    control={Input}
                    name="libelle"
                    value={projet?.libelle ?? ''}
                    required
                    error={isError(errors, 'subventionsPercues-referenceDossier')}
                    onChange={handleChange}
                    fluid
                    debounced
                    data-testid="libelleProjet"
                    width={16}
                    input={{ ref: mainInput }}
                  />
                  )}
              />
            </Form.Group>

            <Form.Field
              label="Référence interne"
              type="text"
              name="referenceInterne"
              // @ts-expect-error
              control={Input}
              value={projet?.referenceInterne ?? ''}
              onChange={handleChange}
              fluid
              data-testid="referenceInterneProjet"
              width={16}
            />

            <UtilisateurProjetInput
              onChange={onChange}
              utilisateursProjet={projet?.utilisateursProjet}
              deletedUtilisateursProjet={projet?.deletedUtilisateursProjet}
              identifiant={projet?.identifiant}
            />

            <Form.Field
              name="status"
              // @ts-expect-error
              control={Dropdown}
              label="Stade"
              fluid
              search
              selection
              value={projet?.status}
              options={PROJET_STATUS_OPTIONS}
              data-testid="statusProjet"
              onChange={handleChange}
            />

            <Form.Group>
              <Form.Field
                label="Date de création"
                width={7}
                control={Date}
                // @ts-expect-error
                name="dateCreation"
                value={projet?.dateCreation}
                onChange={handleChangeDate}
                fluid
                data-testid="dateCreationProjet"
              />

              <Form.Field
                label="Commencement d'exécution"
                control={Date}
                width={4}
                // @ts-expect-error
                name="dateExecution"
                value={projet?.dateExecution}
                onChange={handleChangeDate}
                fluid
                data-testid="dateExecutionProjet"
              />

              <Tooltip
                {...getTooltipError(errors, 'subventionspercues-dateFin')}
                trigger={(
                  <Form.Field
                    control={Date}
                    // @ts-expect-error
                    name="dateFin"
                    label="Achèvement de l'opération"
                    value={projet?.dateFin}
                    onChange={handleChangeDate}
                    fluid
                    width={5}
                    data-testid="dateFinProjet"
                  />
                  )}
              />
            </Form.Group>

            <EtiquettesInput
              identifiantEntite={projet?.identifiantEntite}
              onChange={onChange}
              selectedEtiquettes={projet?.etiquettes}
              allowAdditions
            />
          </Grid.Column>

          <Grid.Column width={4}>
            <div className="avatarWrapper">
              <Avatar
                identifiant={projet?.identifiant}
                identifiantDocInfo={projet?.identifiantDocInfo}
                handleChange={onChange}
              />
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <ProjetComposant
              composants={projet?.composants}
              deletedComposants={projet?.deletedComposants}
              onChange={onChange}
              status={projet?.status}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  );
};

export default ProjetInformation;
