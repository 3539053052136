import { combineServices } from '@jvs-group/jvs-mairistem-store';

import { createFicheService } from '@jvs-group/jvs-mairistem-fiche';
import { createListeService } from '@jvs-group/jvs-mairistem-liste';
import { isNil } from 'lodash';
import type { Projet } from '@/src/interfaces/projet';

const getUrl = (data, endpoint: string, method: string) => {
  const { identifiant } = data;
  const params = 'withComposants&withEtiquettes&withConditionsVersements&withSubventions&withUtilisateursProjet';

  switch (method) {
    case 'GET':
      if (isNil(identifiant)) {
        return '/api/finances/subventionsPercues/projets';
      }
      return `/api/finances/subventionsPercues/projets/${identifiant}?${params}`;
    case 'PUT': case 'DELETE':
      return `/api/finances/subventionsPercues/projets/${identifiant}`;
    case 'POST': default:
      return '/api/finances/subventionsPercues/projets';
  }
};

const projetService = createFicheService<Projet>('projets', getUrl);
const projetListeService = createListeService<Projet>(
  'projets',
  getUrl,
  { meta: { endPoint: { search: '' }, perPage: 50 } },
);

export default combineServices<Projet>(projetListeService, projetService);
